import React from "react";
import SEO from "../components/functional/seo";
import DesignProps from "../components/molecules/design-props";
import MediaBlock from "../components/organisms/media-block";
import Button from "../components/atoms/button";
import DesignMenu from "./design-menu.json";

function DesignMediaBlock() {
  const mediaBlockMenu = DesignMenu.find((dm) => dm.slug === "media-block");
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Media Blocks" />
      <h2>Media Blocks</h2>
      <p className="design__text p2">
        Media Blocks are sections of content that usually includes one or more
        of: images, headlines, text, and call-to-action buttons. They are used
        to provide additional information about specific products or the Buffy
        brand.
      </p>
      <div className="sp-btm-mrg-m">
        {mediaBlockMenu.submenu.map((item) => (
          <Button
            className="shared__link"
            route={`/${item.slug}`}
            key={item.slug}>
            {item.title}
          </Button>
        ))}
      </div>
      <div className="design__stories">
        <div className="design__story-section">
          <DesignProps component={MediaBlock} title="Media Block Properties" />
        </div>
      </div>
    </div>
  );
}

export default DesignMediaBlock;
